const SegmentEvents = (function () {
    const context = {
        channel: "Web App",
    };

    function trackLeadSubmitted(form, context = {}) {
        onAnalyticsReady(() => {
            let traits = {}
            const formData = {}

            const targetProps = {
                "name": "name",
                "first_name": "first_name",
                "last_name": "last_name",
                "email": "email",
                "phone_number": "phone",
                "phone": "phone",
                "mobile phone": "phone",
                "mobilephone": "mobilephone",
            };

            form
                .querySelectorAll("input[data-name], select[data-name], textarea[data-name]")
                .forEach((field) => {
                    const fieldName = toSnakeCase(field.dataset.name.toLowerCase());
                    formData[fieldName] = field.value;

                    if (Object.keys(targetProps).includes(fieldName)) {
                        const value = field.value;

                        if (fieldName == "name") {
                            traits = getFirstLastName(traits, value);
                        } else if (targetProps[fieldName] == "phone") {
                            traits[targetProps[fieldName]] = standardisedPhoneNumber(value);
                        } else {
                            traits[targetProps[fieldName]] = value;
                        }
                    }
                });

            traits["lead_type"] = "form";
            traits["form_data"] = formData;
            traits["form_name"] = form.dataset.name;

            analytics.track("Lead Submitted", traits, context);
        });
    }
    
    function trackProductListViewed() {
        let products = [];

        const productList = document.querySelector("[data-product-list]");

        if (productList === null || typeof productList === "undefined") return;

        const productItems = productList.querySelectorAll(
            "[data-product-list-details] [data-product-item]"
        );

        productItems.forEach((item) => {
            const productTraits = {
                id: item.dataset.productPropId,
                name: item.dataset.productPropName,
                sku: item.dataset.productPropSku,
                price: item.dataset.productPropPrice,
            };

            products.push(productTraits);
        });

        const traits = {
            products,
            list_name: productList.dataset.productPropListName,
        };

        analytics.track("Product List Viewed", traits, { context });
    }

    function trackOrderStarted() {
        const traits = {
            checkout_id: crypto.randomUUID(),
        };

        analytics.track("Order Started", traits, { context });
    }

    function trackActivitySearched(activity, covered) {
        const traits = {
            activity,
            covered,
        }

        analytics.track("Activity Searched", traits, { context })
    }

    function onAnalyticsReady(callbackFn) {
        if (typeof analytics?.ready === "function") {
            analytics && analytics.ready(callbackFn);
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    return {
        trackProductListViewed,
        trackOrderStarted,
        trackActivitySearched,
        onAnalyticsReady,
        trackLeadSubmitted,
    };
})();